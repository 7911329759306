.sliderNavButton {
  position: absolute;
  top: calc(50% - 50px);
  border-radius: 100%;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 9;
}

.sliderNavPrevious {
  left: 0px;
  margin-left: -50px;
  background: linear-gradient(to right, #000000aa, #00000055 80%, #00000020);
}

.sliderNavNext {
  right: 0px;
  margin-right: -50px;
  background: linear-gradient(to left, #000000aa, #00000055 80%, #00000020);
}
