.property-list {
  display: none;
  @apply w-120;
}

.map-container {
  flex: 1;
  display: flex;
  @apply h-180 relative;
  min-height: 40rem;
}

.root {
  @apply flex-col-reverse flex flex-1 flex-wrap;
}

@screen lg {
  .property-list {
    display: block;
  }

  .root {
    @apply space-x-4;
  }

  .root {
    @apply flex flex-row-reverse md:flex-row flex-wrap;
  }
}
